import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import AlertPOP from "../../../../routes/AlertPOP";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import Professons from "./Professons"
import nigeriaStates from "./nigeriaStates"
import { ContextData } from "../../../../context/Context";
import { toast } from "react-toastify";


export default function FormMember() {


  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState("");

  const {setRefreshAmount,RefreshAmount,accesstoken,Person,Refresh,setRefresh,compressedImage,setCompressedImage,compressedNINImage,setCompressedNINImage} = React.useContext(ContextData)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };



  //Form start---------->
  const countries = [
    "United States",
    "China",
    "India",
    "Brazil",
    "Russia",
    "United Kingdom",
    "France",
    "Germany",
    "Japan",
    "South Korea",
    "Canada",
    "Australia",
    "Mexico",
    "South Africa",
    "Argentina",
    "Saudi Arabia",
    "Spain",
    "Italy",
    "Netherlands",
    "Turkey",
    "Indonesia",
    "Pakistan",
    "Nigeria",
    "Egypt",
    "Bangladesh",
    "Vietnam",
    "Philippines",
    "Thailand",
    "Colombia",
    "Chile",
    "Nigeria",
    "Ethiopia",
    "Egypt",
    "DR Congo",
    "South Africa",
    "Tanzania",
    "Kenya",
    "Uganda",
    "Algeria",
    "Sudan",
    "Morocco",
  ];

  const [formData, setFormData] = useState({

    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    mobileNumber: "",
    origin: "",
    country: "",
    stateOfOrigin: "",
    stateofresident:"",
    other_state:"",
    other_profession: "", 
    dateOfBirth: "",
    nin: "",
    profession: "",
    position: "",
    member_address: "",
    department:"",
    m_status: ""
  //  ds_ability: ""

  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };




// Handle Submit
  const handleSubmit = async(e) => {

    e.preventDefault();
    const Yeah = new Date().getFullYear()

    

    if(validator.isEmpty(formData.firstName.trim()) || validator.isEmpty(formData.lastName.trim()) || validator.isEmpty(formData.email.trim()) || validator.isEmpty(formData.mobileNumber.trim()) || validator.isEmpty(formData.origin.trim()) || validator.isEmpty(formData.country.trim()) || validator.isEmpty(formData.dateOfBirth.trim()) || validator.isEmpty(formData.nin.trim()) || validator.isEmpty(formData.profession.trim()) || validator.isEmpty(formData.position.trim()) || validator.isEmpty(formData.m_status.trim()) ){
       
      handleClick("left")
      setmessage("Please fill out all fields")
         return
    }


    if(!validator.isNumeric(formData.mobileNumber.trim())){
      handleClick("left")
      setmessage("Mobile is not a number")
    }

    if(!validator.isEmail(formData.email.trim())){
      handleClick("left")
      setmessage("Email is not an email")
    }


    if(validator.isEmpty(compressedImage.trim())){
      handleClick("left")
      setmessage("Capture Member Image")
      return
    }

    if(formData.nin !== "N/A" && validator.isEmpty(compressedNINImage.trim())){

      handleClick("left")
      setmessage("Capture NIN Image")
      return

    }

    let FirstIndex = formData.dateOfBirth.indexOf("-")
    let SliceValue = formData.dateOfBirth.slice(0,FirstIndex)
    let age = Yeah - SliceValue
    const data = {...formData,age,registerer_token: Person !== null && Person.token}

    const formDataObj = new FormData();
    
    Object.keys(data).forEach(key => {
      formDataObj.append(key, data[key]);
    });
    formDataObj.append('memberfile', await (await fetch(compressedImage)).blob());
    formDataObj.append('ninfile', await (await fetch(compressedNINImage)).blob());


     // Submit the form (you can implement your submission logic here)

      axios.post(`${baseUrl}/auth/registermember`,formDataObj,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`   
        }}).then(res=>{

          handleClick("left")

            
            if(res.data.status){

              setmessage(res.data.statusText)
              setRefresh(!Refresh)
              
             setFormData({
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              origin:"",
              country: "",
              dateOfBirth: "",
              nin: "",
              profession: "",
              position: "",
              stateOfOrigin: "",
              other_state:"",
              other_profession:"",
              member_address:"",
              gender: "",
              department: "",
              m_status: ""
            }); 
           setCompressedImage("")
           setCompressedNINImage("")
           setRefreshAmount(!RefreshAmount)

            }


            if(!res.data.status){
              setmessage(res.data.statusText)
            }
          

              
        }).catch(err=> {
              handleClick("left")
            if(err) return  setmessage(err.message)
           
        })





        
  };

  const handleCitizenshipTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      origin: selectedOriginType,
      country: selectedOriginType === 'indigen' ? 'Nigeria' : 'Select State'
    }))

  }



const handleGenderTypeChange =  (e) => {

  const selectedOriginType = e.target.value

  setFormData((prevData) => ({
    ...prevData,
    gender: selectedOriginType,
   // country: selectedOriginType === 'indigen' ? 'Nigeria' : 'Select State'
  }))

}

const handle_M_status_TypeChange = (e) => {

  const selectedOriginType = e.target.value

  setFormData((prevData) => ({
    ...prevData,
    m_status: selectedOriginType
  }))

}


const handleDepmentTypeChange =  (e) => {

  const selectedOriginType = e.target.value

  setFormData((prevData) => ({
    ...prevData,
    department: selectedOriginType,
   // country: selectedOriginType === 'indigen' ? 'Nigeria' : 'Select State'
  }))

}



  //Form end --------->

  return (
    <React.Fragment>
    
        <AlertPOP
         Open={openAlert}
         handleClose={handleCloseAlert}
         transition={transition}
         message={message}
      />

<form onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.firstName}
                onChange={handleInputChange("firstName")}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange("lastName")}
              />

      
              <TextField
                select
                label="Marital Status"
                variant="outlined"
                fullWidth
                name="m_status"
                margin="normal"
                value={formData.m_status}
                onChange={handle_M_status_TypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
              <MenuItem value="widow">Widow</MenuItem>
              <MenuItem value="widower">Widower</MenuItem>
              </TextField>

                <TextField
                select
                label="Gender"
                variant="outlined"
                fullWidth
                name="gender"
                margin="normal"
                value={formData.gender}
                onChange={handleGenderTypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
               </TextField>

              <TextField
                select
                label="Department"
                variant="outlined"
                fullWidth
                name="department"
                margin="normal"
                value={formData.department}
                onChange={handleDepmentTypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="Mother Body">Mother Body</MenuItem>
              <MenuItem value="First Aid">First Aid</MenuItem>
              <MenuItem value="Women Wing">Women Wing</MenuItem>
              </TextField>

              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
              <TextField
                label="Mobile Number"
                type="tel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.mobileNumber}
                onChange={handleInputChange("mobileNumber")}
              />

               <TextField
                select
                label="Nationality"
                variant="outlined"
                fullWidth
                name="origin"
                margin="normal"
                value={formData.origin}
                onChange={handleCitizenshipTypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="indigen">Indigen</MenuItem>
              <MenuItem value="citizen">Citizen</MenuItem>
              </TextField>

 
              <TextField
                select
                label="Country of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.country}
                disabled={formData.origin === 'indigen'}
                onChange={handleInputChange("country")}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
              <label>Date Of Birth:</label>
              <TextField
               
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.dateOfBirth}
                type="date"
                onChange={handleInputChange("dateOfBirth")}
              />
              <TextField
                label="NIN"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.nin}
                onChange={handleInputChange("nin")}
              />
               {
                formData.nin !== "" && <i>Capture NIN, is required *</i>
               }
              <TextField
                select
                label="Profession"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.profession}
                onChange={handleInputChange("profession")}
              >
                {Professons.map((profession) => (
                  <MenuItem key={profession} value={profession}>
                    {profession}
                  </MenuItem>
                ))}
              </TextField>
               {
                formData.profession === "Other" && 
                <TextField
                label="Write name of the profession"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.other_profession}
                onChange={handleInputChange("other_profession")}
              />
               }

              <TextField
                label="Home address"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.member_address}
                onChange={handleInputChange("member_address")}
              />
              <TextField
                label="Position"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.position}
                onChange={handleInputChange("position")}
              />

              {
                formData.origin === 'indigen' &&
              <TextField
                select
                label="State of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.origin === "indigen" ? formData.stateOfOrigin : "N/A" }
                onChange={handleInputChange("stateOfOrigin")}
              >
                {nigeriaStates.map((state) => (
                  <MenuItem key={state} value={formData.origin === "indigen" ? state : "N/A"}>
                    {state}
                  </MenuItem>
                ))}
              </TextField> 
              }
              {formData.stateofresident}
              {
                formData.origin === "citizen" && 
                <>
                <TextField
               label="State of resident"
               variant="outlined"
               fullWidth
               margin="normal"
               value={formData.origin === "citizen" ? formData.stateofresident : "N/A"}
               onChange={handleInputChange("stateofresident")}
             />
           
                <TextField
               label="State of Origin from the Origin Country"
               variant="outlined"
               fullWidth
               margin="normal"
               value={formData.origin === "citizen" ? formData.other_state : "N/A"}
               onChange={handleInputChange("other_state")}
             />
                </>
              }
              
              
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>

              {image && <img src={image} alt="Captured" />}
            </form>
       
    </React.Fragment>
  );
}
