import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/Router';
import Context from './context/Context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className="App">
       <ToastContainer 
     position="top-center"
     />
    <BrowserRouter>
     <Context>
     <Router/>
     </Context>
    
    </BrowserRouter>


    
   

    </div>
  );
}

export default App;
