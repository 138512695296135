import React, { useContext, useState } from 'react';
import {
  TextField,
  Button,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));








const Settings = () => {


  const Navigate = useNavigate()


  const ReturnBack = () =>{
        
    Navigate("/dashboard/members") 
             
  }





    const classes = useStyles();
    const {Person,accesstoken} = useContext(ContextData)
    const [formData, setFormData] = useState({
    email: Person !== null && Person.email,
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
    token:  Person !== null && Person.token,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value.trim() }));
  };

  const handleSubmit = (e) => {

    e.preventDefault();

    // Check if any field is empty
    if (Object.values(formData).some((value) => value === '')) {
      alert('Please fill in all fields');
      return;
    }

    if(formData.repeatPassword !== formData.newPassword){
           
      toast.warn("Password Not Match")
      return
    }
   

    axios.put(`${baseUrl}/auth/update_passwrd`,formData,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${accesstoken !== null && accesstoken}`
    
      }}).then(res=>{

          
            if(res.data.status){
             
               toast.success(res.data.statusText)
               setFormData({
                oldPassword: '',
                newPassword: '',
                repeatPassword: '',
               })
             

            }else{

                  toast.warn(res.data.statusText)
            }
                
      }).catch(err=> {

          if(err) return toast.warn(err.message)
             
      })

    

    // Submit the form 3155781761
   
  };


  return (
    <div  className="con-dev flex justify-center items-center bg-indigo-950" style={{width:"100%",height:"100%"}}>
       
       
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled
              />
              <TextField
                label="Old Password"
                type="password"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
                required
              />
              <TextField
                label="New Password"
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                required
              />
              <TextField
                label="Repeat New Password"
                type="password"
                name="repeatPassword"
                value={formData.repeatPassword}
                onChange={handleChange}
                required
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Submit
              </Button>
            </form>
             {
              Person.acc_level !== null && <>{Person.acc_level === "0" &&
              
                <Button className='Hover-'  onClick={ReturnBack} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
                return back
             </Button>
              }</> 
             }
          </Paper>
        
    </div>
  );
};

export default Settings;


// get_data_adminuser