import React, { useContext } from 'react'
import { Navigate, Route, Routes,Outlet } from 'react-router-dom'
import Page404 from './Page404'
import Login from '../components/Login/Login'
import Main from './Main'
import AdminUsers from '../components/Section/pages/AdminUsers'
import Members from '../components/Section/pages/Members'
import Statistics from '../components/Section/pages/Statistics'
import User from '../components/Section/pages/User'
import PrivateRoute from './PrivateRoute/PrivateRoute'
import StatisticsReport from '../components/Section/pages/St-ReportBar/StatisticsReport'
import Logs from '../components/Section/pages/Logs'
import Settings from '../components/Section/pages/Settings'
import SetPayment from '../components/Section/pages/SetPayment'
import Home from '../components/home/Home'
import Userlogin from '../components/Login/Userlogin'
import Verification from '../components/Section/pages/Verification'
import Permission from './Permission'
import Approve from '../components/Section/pages/Approve'
import { ContextData } from '../context/Context'
import Pending from '../components/Section/pages/Pending'
import IDCard from '../components/Section/pages/ID-Card'










function Router() {

     const {accesstoken} = useContext(ContextData)


  
         return (
           <Routes>
        
             {/* Check user before get access to route */}
           
       
            {/* If user success then allow access to the routes */}
       
            <Route element={<PrivateRoute /> } >
       
            <Route path='/dashboard' element={<Main/>}> 
        
           {/* Close route for unauthorize user */}
       
            <Route element={<Permission/>}>
       
            <Route path="payment" element={<SetPayment/>} />
            <Route path="adminusers" element={<AdminUsers/>} />
            <Route path="user" element={<User/>} />
            <Route path="statistics" element={<Statistics/>} />
            <Route path="report_statistics" element={<StatisticsReport/>} />
            <Route path="logs" element={<Logs/>} />
            <Route path="verification" element={<Verification/>} />
            <Route path="approve" element={<Approve/>} /> 
            <Route path="pending" element={<Pending/>} /> 
            <Route path="id_card" element={<IDCard/>} /> 
            
            </Route> 
       
       
            <Route path="settings" element={<Settings/>} />
            <Route path="members" element={<Members/>} />
       
       
            </Route>
           
           </Route>
             
           {/* Home Page */}
           <Route path="/home" element={<Home/>} /> 
       
           {/* Catch route */}
           <Route element={<Navigate to="/home"/>} index />
           <Route path='/404' element={<Page404/>} />
           <Route path='*' element={<Navigate to="/404"/>}  />
        
           <Route path='/login' element={<Login/>}  />
           <Route path='/user_login' element={<Userlogin/>}  />
       
       
           </Routes>
         )
     

}

export default Router