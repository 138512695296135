import axios from 'axios'
import React, { useContext, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import { ContextData } from '../../context/Context'
import baseUrl from '../../BaseURL'
import Alert from '../../routes/AlertPOP'
import Loader from '../../routes/Loader'









function Login() {

    
        const [Username,setUsername] = useState("")
        const [Password,setPassword] = useState("")
        const [message,setmessage] = useState("")
        const {setaccesstoken,setUserToken} = useContext(ContextData) 
        const Navigate = useNavigate()


        const [open, setOpen] = useState(false);
        const [transition, setTransition] = useState(undefined);
        const [OpenLoader,setOpenLoader] = useState(false)


        // Handle the alert transition and function
      
        const handleClick = () => {
         setTransition(() => "TransitionLeft")
          setOpen(true);
        };
         // Handle the close alert box
        const handleClose = () => {
          setOpen(false);
        };


        // Handle the submit function

        const HandleSubmit = (e) =>{

             // Handle the refresh behavior   
             e.preventDefault();
            
             handleClick("left")
        
             if(Username.trim() === "" || Password.trim() === ""){

                  setmessage("No Empty input allow")
                  
                  return
             }

              // loader
              setOpenLoader(true)

             const data = {
                   email: Username,
                   passwrd: Password
             }

             axios.post(`${baseUrl}/free/admin_u_login`,data,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json'
              }}).then(res=>{

                  
                    if(res.data.status){

                      if(res.data.userType === "user"){
  
                       setmessage(res.data.text)
                       setaccesstoken(res.data.accesstoken)
                       localStorage.setItem("access",res.data.accesstoken)
                       setUserToken(res.data.token)
                           // Loader
                           setOpenLoader(true)
                           setmessage("Processing...")
                          const timer = setTimeout(()=>{
                            // loader
                            setOpenLoader(false)
                             
                            // route the user 
                             Navigate("/dashboard/members")
  
                            clearTimeout(timer)
  
                          },100)
  
                      }else{
                       setmessage(res.data.text)
                       setaccesstoken(res.data.accesstoken)
                       localStorage.setItem("access",res.data.accesstoken)
                       localStorage.setItem("","100")
                       setUserToken(res.data.token)
                           // Loader
                           setOpenLoader(true)
                           setmessage("Processing...")
                          const timer = setTimeout(()=>{
                            // loader
                            setOpenLoader(false)
                             
                            // route the user 
                             Navigate("/dashboard/statistics")
  
                            clearTimeout(timer)
  
                          },100)
                      }
                    


                    }
                      


                    if(!res.data.status){
                    setmessage(res.data.text)
                     // loader
                   setOpenLoader(false)
                  }
                    
              }).catch(err=> {

                  if(err)  setmessage(err.message)
                     
                   // loader
                   setOpenLoader(false)
              })



        }



  return (
    <div className=' h-screen w-full flex items-center justify-center flex-col login-con-bg'>
      <Alert
       Open={open}
       handleClose={handleClose}
       transition={transition}
       message={message}
      />
      <Loader
       OpenLoader={OpenLoader}
      />

       
   <div style={{backgroundColor:"rgba(0,0,0,0.679)"}} className='flex flex-col items-center justify-center p-5 rounded-md text-cyan-50 login-form-con'>
       <h1 className='text-4xl font-extrabold mb-5 login-h-text'>FITYANUL ISLAM OF NIGERIA DBMS</h1>
    <form className="flex flex-col w-96" onSubmit={HandleSubmit}>

    <div class="">
      <label for="inputEmail4" class="form-label font-bold">Email</label>
      <input
       type="email" 
       className="form-control font-bold" 
       id="inputEmail4"
       value={Username}
       onChange={(e) =>setUsername(e.target.value)}
       />
    </div>

    <div class="mt-2">
      <label for="inputPassword4" className="form-label font-bold">Password</label>
      <input 
       type="password"
       className="form-control font-bold"
       id="inputPassword4"
       value={Password}
       onChange={(e) =>setPassword(e.target.value)}
         />

    </div>

    <div className=" font-bold mt-5 flex justify-center items-center">
      <button type="submit" className="btn btn-primary text-gray-50 bg-gray-900 font-bold">Login</button>
    </div>

  </form>

   </div>
  </div>
  )
}

export default Login