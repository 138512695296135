import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import AlertPOP from "../../../../routes/AlertPOP";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import { Avatar } from "@mui/material";
import MemberDataPage from "./MemberDataPage";
import { ContextData } from "../../../../context/Context";
import ViewNIN from "./ViewNIN";


export default function ViewPopUserEdit(props) {



  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {Refresh,setRefresh,accesstoken,Person} = React.useContext(ContextData)

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };



  //Form start---------->

  const professions = ["Engineer", "Doctor", "Teacher", "Other"];
  const states = ["Abia", "Lagos", "Kano", "Ogun", "Other"];
  const countries = [
    "United States",
    "China",
    "India",
    "Brazil",
    "Russia",
    "United Kingdom",
    "France",
    "Germany",
    "Japan",
    "South Korea",
    "Canada",
    "Australia",
    "Mexico",
    "South Africa",
    "Argentina",
    "Saudi Arabia",
    "Spain",
    "Italy",
    "Netherlands",
    "Turkey",
    "Indonesia",
    "Pakistan",
    "Nigeria",
    "Egypt",
    "Bangladesh",
    "Vietnam",
    "Philippines",
    "Thailand",
    "Colombia",
    "Chile",
    "Nigeria",
    "Ethiopia",
    "Egypt",
    "DR Congo",
    "South Africa",
    "Tanzania",
    "Kenya",
    "Uganda",
    "Algeria",
    "Sudan",
    "Morocco",
  ];

  const [formData, setFormData] = useState({

    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    origin: "",
    country: "",
    stateOfOrigin: "",
    stateofresident:"",
    other_state:"",
    dateOfBirth: "",
    nin: "",
    profession: "",
    position: ""

  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };




// Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const Yeah = new Date().getFullYear()

    // Check for empty fields
    if(validator.isEmpty(formData.firstName.trim()) || validator.isEmpty(formData.lastName.trim()) || validator.isEmpty(formData.email.trim()) || validator.isEmpty(formData.mobileNumber.trim()) || validator.isEmpty(formData.origin.trim()) || validator.isEmpty(formData.country.trim()) || validator.isEmpty(formData.dateOfBirth.trim()) || validator.isEmpty(formData.nin.trim()) || validator.isEmpty(formData.profession.trim()) || validator.isEmpty(formData.position.trim()) ){
       
      handleClick("left")
      setmessage("Please fill out all fields")
         return
    }


    if(!validator.isNumeric(formData.mobileNumber.trim())){

      handleClick("left")
      setmessage("Mobile is not a number")
    }

    if(!validator.isEmail(formData.email.trim())){
      handleClick("left")
      setmessage("Email is not an email")
    }

    let FirstIndex = formData.dateOfBirth.indexOf("-")
    let SliceValue = formData.dateOfBirth.slice(0,FirstIndex)
    let age = Yeah - SliceValue
    const data = {...formData,age}
     
      // Submit the form (you can implement your submission logic here)

      axios.post(`${baseUrl}/auth/registermember`,data,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{

          handleClick("left")
          
            if(res.data.status){

              setmessage(res.data.statusText)
            // setRefresh(!Refresh)
              
             setFormData({
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              origin:"",
              country: "",
              dateOfBirth: "",
              nin: "",
              profession: "",
              position: "",
              stateOfOrigin: "",
              other_state:""
            }); 


            }


            if(!res.data.status){
              
              setmessage(res.data.statusText)
            }


              
        }).catch(err=> {
              handleClick("left")
            if(err) return  setmessage(err.message)
           
        })





  };

  const handleCitizenshipTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      origin: selectedOriginType,
      country: selectedOriginType === 'indigent' ? 'Nigeria' : 'Select State'
    }))

  }

  //Form end --------->

  return (
    <React.Fragment>
       
      <Button  variant="contained" onClick={handleClickOpen}>
         {Person !== null && Person.acc_level === "100" ? "View/Edit" :"View"}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AlertPOP
         Open={openAlert}
         handleClose={handleCloseAlert}
         transition={transition}
         message={message}
      />
        <DialogTitle id="alert-dialog-title">
          <div className="flex flex-col justify-center">
         <div className="flex items-center ">
       <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)'}}  style={{ width: 150, height: 150, margin: "" }} alt="d" src={`${baseUrl}/files/assets/MemberPictures/${props.pic}`} />
            <div className="ml-5">
               <p><b>Registration ID:</b> {props.r_ID}</p>
               <p><b>NIN ID:</b> {props.ninId}</p>
            </div>
         </div>

           { //  "NIN not available for this Member" : 
            props.nin !== "N/A" &&
            <ViewNIN nin={props.nin} ninId={props.ninId}  />
            }

          </div>
           
           

        </DialogTitle>
        <div className="d-d-m-p-con">
        <DialogContent> 
         
        <MemberDataPage token={props.token} vr={props.vr} />

        </DialogContent>
          </div> 
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Done</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
