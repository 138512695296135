import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogReport() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button sx={{background:"red",color:"white", marginLeft:"3px"}} variant="contained" onClick={handleClickOpen}>
         Reject
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Provide report for rejecting the member Bio data"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ..
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>

        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}