import React from 'react';
import PopUpDialug from './PopUpDialug';






export default  function Home(){


    const d = new Date();
    let Year =  d.getFullYear()

    

    return(
          
          <div  style={{boxShadow:"0px 6px 10px rgba(0,0,0,0.379)"}}  className=' home-con h-screen grid grid-cols-12'>
            
            {/* Header */}

            <div className='bg-zinc-50  p-1 flex justify-center items-center relative col-start-1 col-end-13 '>

                <div className='absolute left-1 flex'>
                <img src='./assets/fit.jpeg' className='w-24 ml-1'/>
                </div>

             <h1 className='text-center font-extrabold text-4xl shadow-xl kssl-Pay-text'>Fityanul Islam Of Nigeria</h1>

               <div className='absolute right-1 flex'>
                <img src='./assets/fit.jpeg' className='w-24 ml-1' />
              
                </div>
             
            </div>
         
           {/* Section */}

          <div className=' col-start-1 col-end-13 flex justify-center z-10' >

             <div className='w-full m-10'>

              {/* <div>
                  <h3 className='text-4xl text-center mb-3'>Register now</h3>
              </div> */}
              {/* <p className='text-justify p-7'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ipsa officia incidunt facilis illo ad iusto aliquid, molestias repellat similique totam suscipit velit, eligendi sed aliquam accusantium provident, maiores repellendus soluta inventore quos quia! Molestiae recusandae maxime delectus commodi iusto consequuntur? Dolores cum accusamus praesentium! Est dolorum sunt totam, quisquam aliquam debitis qui error nulla. Provident repellendus repellat itaque eum excepturi illo perspiciatis corporis deserunt ducimus! Veritatis recusandae voluptatem illum laboriosam odio, enim deserunt minima similique, soluta modi neque saepe, fugiat hic officia itaque illo facilis earum delectus! Exercitationem consequatur nihil odit incidunt voluptatum ex illo repellat veritatis maiores necessitatibus officiis similique dolor nobis, quo sint libero sed labore neque? Quia cum voluptatem molestias beatae placeat at maiores ipsa odit dignissimos optio vero earum, aut fugiat debitis soluta culpa expedita aliquam. Veniam dolores, culpa, amet veritatis neque libero deleniti sint dolor voluptas earum sunt. Molestias, explicabo! Nesciunt repudiandae similique minus? Commodi quidem, impedit cupiditate magni tenetur tempora possimus debitis eos sapiente totam nulla corporis sit ex? Itaque consectetur non perspiciatis corrupti, repellendus labore. Aut dolores optio debitis vitae corporis saepe quasi ducimus nobis quae laudantium dolorem itaque praesentium eveniet, repellendus ad corrupti. Adipisci delectus ducimus, iste voluptatibus harum debitis magnam.
              </p> */}


               {/* Pop button to Pay */}

               {/*  <div className='w-full h-36 flex justify-center items-center'>
                    <PopUpDialug/>
                </div> */}


             </div>

          </div>

        <div className='footer-home text-center col-start-1 col-end-13 flex justify-center items-cente flex-col'>
                <h3 className='font-extrabold'>All right reserved@{Year}: Fityanul Islam Of Nigeria</h3>
                 <h3 className=''><a target='_blank' href='mmt-ng.com'>Developed By MastermindTech</a></h3>
        </div>

     </div>
    )



}


