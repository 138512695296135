import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { usePaystackPayment } from 'react-paystack';
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import AlertPOP from "../../../../routes/AlertPOP";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import { ContextData } from "../../../../context/Context";
import { Avatar } from "@mui/material";
import MaskText from "./MaskText";
import TransactionList from "./TransactionList";
import FormRecharge from "./FormRecharge";


export default function ViewPopRechargeUser(props) {




  const [open,setOpen] = React.useState(false);
  const {setLoader,accesstoken,setRefreshAmount,RefreshAmount} = React.useContext(ContextData)
  const [PaymentData,setPaymentData] = React.useState(null)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  const [formData, setFormData] = useState({
    quantity: "",
    registration_type_fee:"",
    ID: props.data.ID,
    user_token: props.data.token
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };


// Handle Submit
  const handleSubmit = (e) => {

    e.preventDefault();
   

    if(validator.isEmpty(formData.quantity) || validator.isEmpty(formData.registration_type_fee.toString()) ){
       
      handleClick("left")
      setmessage("Please fill out all fields")
      return
    }

       // Call Payment Getway if not empty
       initializePayment(onSuccess,onClose)

  };


  // Send user Data after payment

  const SubmitFormdata = () => {

    const data = {...formData,paid_total: formData.registration_type_fee * formData.quantity}
     
    // Submit form data after payment

    axios.put(`${baseUrl}/auth/user_amount`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${accesstoken !== null && accesstoken}`
      }}).then(res=>{

        handleClick("left")
        
        setRefreshAmount(!RefreshAmount)

          if(res.data.status){

           // setmessage(res.data.statusText)
           // setRefresh(!Refresh)
           
            
           setFormData({
            quantity: "",
            registration_type_fee:""
          }); 
          }


          if(!res.data.status){
            
            setmessage(res.data.statusText)
          }
         
      }).catch(err=> {
            handleClick("left")
          if(err) return  setmessage(err.message)
         
      })


  }


    // Handle on success after payment
  const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
        SubmitFormdata()
    // console.log(formData)
   //    alert("donr")
    };
  
// Handle close if no succeeded payment
 const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
  // alert("closed")
}
    

// Payment Getway functions
const initializePayment = usePaystackPayment(
  {
  publicKey: 'pk_test_279445fbbe7db5c0d413bbd532027d14191b107a',
  reference: (new Date()).getTime().toString(),
  email: props.data.email,
  amount: Number(`${formData.registration_type_fee * formData.quantity}00`),
  firstname:  props.data.fname,
  lastname: props.data.lname
   },
   
   )




  const handleQuantityTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      quantity: selectedOriginType
    }))

  }

  const handleRegistrationTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      registration_type_fee: selectedOriginType 
    }))

  }

  
  React.useEffect(()=>{
      

    async function FetchData(){
            //  setLoader(true)  
           
          try {
            const response = await axios.get(`${baseUrl}/auth/get_set_payment`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 

            setPaymentData(response.data)
            setLoader(false)
           
          } catch (err) {
             if(err) console.log(err.message)
            // setLoader(false)
          }

         
    }FetchData()


},[])

  //Form end --------->

  return (
    <React.Fragment>
    
      <Button className='Hover-' onClick={handleClickOpen} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(19, 11, 211)', margin:'9px 0px'}}  size="large" variant="contained">
      
      {
        props.value === "r" && "Recharge" 
      }
       {
        props.value === "t" && "Transactions" 
      }
       {
        props.value === "l" && "List" 
      }
     </Button>
      {/*  */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AlertPOP
         Open={openAlert}
         handleClose={handleCloseAlert}
         transition={transition}
         message={message}
      />
        <DialogTitle id="alert-dialog-title">
      
          
        </DialogTitle> 
        <DialogContent>
       

        {
        props.value === "r" && 
         <FormRecharge
          ID = {props.data.ID}
          token={props.data.token}
          email={ props.data.email}
          fname={props.data.fname}
          lname={props.data.lname}
         
         />
        }

        {
          props.value === "t" && 
          <div className="w-80 h-48 rounded-sm mb-3 relative">
            <TransactionList
              data={props.data}
            /> 
          </div>
        }


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
