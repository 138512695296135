import React from 'react'
import { Outlet } from 'react-router-dom'

function Section() {
  return (
    <div className='section'> 
    
    <Outlet/>
    
    </div>
  )
}

export default Section