import React from 'react'

function Page404() {
  return (
    <div className=' text-center flex justify-center items-center w-full h-screen flex-col'>
     <h1 className='text-9xl font-extrabold'>404</h1>
    <p><button>return to login page</button></p>
    </div>
  )
}

export default Page404