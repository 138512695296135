import React from 'react'
import { BarChart } from '../Contents/statistics/BarChart'
import { PieChart } from '../Contents/statistics/PieChart'
import { ScatterChart } from '../Contents/statistics/ScatterChart'
import { LineChart } from '../Contents/statistics/LineChart'

function Statistics() {
  return (
    <div className='bg-stone-900 h-full'>
    {/* First Section Chart  */}
     <LineChart/> 

    </div>
  )
}

export default Statistics