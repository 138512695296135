import React, { useContext } from 'react'
import Nav from './Nav'
import { ContextData } from '../../context/Context'

function Aside() {


   const{Person,setRefresh,Refresh} = useContext(ContextData)

   /* 
    const timer = setInterval(()=>{
      setRefresh(!Refresh)
      clearInterval(timer)
    },100000) */


  return (
     <div className="aside shadow-2xl">
 

    <div className='bg-gray-900 text-slate-100 p-2'>
    <h1 className='text-3xl font-bold text-center'>DBMS</h1>
    </div>
      
       
       <div className='bg-gray-900 text-slate-100 p-2'>
       <h4 className='text-1xl font-bold text-center'>{Person !== null && `Welcome ${Person.fname}`} </h4>
       </div>


      <div className='flex h-auto mt-14 pt-4' >
        <Nav/>
      </div> 



     </div>
  )
}

export default Aside