import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL'; 
import AddUserDialog from '../Contents/User/AddUserDialog';
import UserViewPopUserEdit from '../Contents/User/UserViewPopUserEdit';
import ViewPopRechargeUser from "../Contents/RechargeUser/ViewPopRechargeUser"
import { toast } from 'react-toastify';
/* import EditStudentPop from './PopDialogue/Student/EditStudentPop';
 */
 
const User = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const rowsPerPage = 6;
  const {setLoader,accesstoken,Refresh,setRefresh,Person} = useContext(ContextData)
 
  useEffect(()=>{
      

    
      async function FetchData(){
              //  setLoader(true)  

            try {
              const response = await axios.get(`${baseUrl}/auth/get_data_user`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${accesstoken !== null && accesstoken}`
                }}) 
              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
              // setLoader(false)
            }

           
      }FetchData()


  },[Refresh])

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };


    const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
    const filteredUsers = List.filter(user => {
    const lowerCaseSearchText = searchText.toLowerCase().trim()

    return (
     (
      user.fname.toLowerCase().includes(lowerCaseSearchText) &&   user.lname.toLowerCase().includes(lowerCaseSearchText) ) ||
      user.mobile.toLowerCase().includes(lowerCaseSearchText) ||
      user.email.toLowerCase().includes(lowerCaseSearchText) ||
      user.profession.toLowerCase().includes(lowerCaseSearchText) ||
      user.stateoforigin.toLowerCase().includes(lowerCaseSearchText)
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const HanldeRemove = async (sttokens) =>{

    
    try {
      const response = await axios.delete(`${baseUrl}/auth/remove_adminuser_user/${sttokens}`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{
              
            if(res.data.status){
               
                    toast.success(res.data.statusText)
                     setRefresh(!Refresh)

            }


        })
          } catch (err) {

       if(err) console.log(err.message)
      
       }



  }




  return (
    <Container>
      <Typography variant="h4" gutterBottom className='flex justify-between items-center'>
        <p className='font-extrabold mt-3'>Register Users</p>
        <div>
          <AddUserDialog />
        </div>
      </Typography>
      
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
     
          <TableHead>
            <TableRow sx={{background:"rgb(0, 24, 69)"}}>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>No.</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Avatar</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Full Name</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>NIN</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Total Registered</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Transactions</TableCell>
              {/* <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Profession</TableCell> */}
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Recharge</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Restrict</TableCell>
              <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Edit</TableCell>
              {
                  Person !== null && <> {Person.acc_level === "100" && 
                    <TableCell  align='center' sx={{color:"white",fontWeight:"800"}}>Remove user</TableCell>
                  }</>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user,index) => (
              <TableRow key={user.id}>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{index + 1}</TableCell>
                <TableCell>
                  <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)'}} alt={user.fname} src={`https://example.com/avatars/${user.id}.png`} />
                </TableCell>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.fname} {user.lname}</TableCell>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.nin}</TableCell>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>

                {/* <Button className='Hover-' onClick={()=> HanldeRemove()} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(19, 11, 211)', margin:'9px 0px'}}  size="large" variant="contained">
                     
                  </Button> */}
                  <ViewPopRechargeUser 
                  value="l" 
                  data={user}
                  />


                </TableCell>

                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>
{/* 
                <Button className='Hover-' onClick={()=> HanldeRemove()} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(19, 11, 211)', margin:'9px 0px'}}  size="large" variant="contained">
                    s
                  </Button> */}
                    <ViewPopRechargeUser 
                    value="t"
                    data={user}
                     />

                </TableCell>

              
               {/*  <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.profession}</TableCell> */}
               
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>

                <ViewPopRechargeUser 
                 value="r"
                 data={user}
                />

                </TableCell>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.acc_lock.toString() === "0" ?
                
                <Button className='Hover-'  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
                   Lock
                 </Button>
                            :
                 <Button className='Hover-'  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(179, 103, 21)', margin:'9px 0px'}}  size="large" variant="contained">
                   Unlock
                </Button>
                }
                </TableCell>
           
                  <TableCell>
                   <UserViewPopUserEdit/>
                  </TableCell> 
 
                  <TableCell>
                    {
                       Person !== null && <> {Person.acc_level === "100" &&

                        <Button className='Hover-' onClick={()=> HanldeRemove(user.token)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
                        Remove
                       </Button>
                       
                       }
                         </> 
                    }
                 

                  </TableCell>
                  
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      )}
    </Container>
  );
};

export default User;
