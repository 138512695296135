import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import { ContextData } from "../../../../context/Context";
import { Avatar } from "@mui/material";
import MaskText from "./MaskText";
import { toast } from "react-toastify";


export default function Deposited(props) {


     

  const [open,setOpen] = React.useState(false);
  const {setLoader,accesstoken,setRefresh,Refresh,Person} = React.useContext(ContextData)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [formData, setFormData] = useState({
    quantity: "",
    registration_type_fee:"",
    submitted_form_amount: props.PendingMembers,
    registerer_token: Person !== null && `${Person.token}`,
    registerer_name: Person !== null && `${Person.fname} ${Person.lname}`,
    registerer_state: Person !== null && `${Person.stateoforigin}`
   // ID: props.data.ID,
   // user_token: props.data.token
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };


// Handle Submit
  const handleSubmit = (e) => {

    e.preventDefault();
    
    if(formData.registration_type_fee === "Select" ){
        toast.warn("Select can not be use as input")
        return
      }

    if(validator.isEmpty(formData.registration_type_fee.toString()) ){
      toast.warn("Please fill out all fields")
      return
    }


  };
  

  // Send user Data after payment

  const SubmitFormdata = () => {

    const data = {...formData,deposited_amount: formData.registration_type_fee * props.PendingMembers}
     
    // Submit form data after payment

    axios.post(`${baseUrl}/auth/deposite`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${accesstoken !== null && accesstoken}`
      }}).then(res=>{

      

          if(res.data.status){

            toast.success(res.data.statusText)
           setRefresh(!Refresh)
           
            
           setFormData({
            registration_type_fee:""
          }); 
          }

         
      }).catch(err=> {
           
          if(err) return  toast.warn(err.message)
         
      })

 
  }




  
  React.useEffect(()=>{
      

    async function FetchData(){
            //  setLoader(true)  
           
          try {
            const response = await axios.get(`${baseUrl}/auth/get_set_payment`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 

          //  setPaymentData(response.data)
          //  setLoader(false)
           
          } catch (err) {
             if(err) console.log(err.message)
            // setLoader(false)
          }

         
    }FetchData()


},[])

  //Form end --------->

  return (
    <React.Fragment>
    
      <Button className='Hover-' onClick={handleClickOpen} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'green', margin:'9px 5px'}}  size="small" variant="contained">
       Deposited
     </Button>
      {/*  */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogTitle id="alert-dialog-title">
      
          
        </DialogTitle> 
        <DialogContent>
       
          
            <div className="w-80 h-80 bg-slate-300 rounded-sm mb-3 relative">

            <h2 className="p-3 bg-slate-900 text-cyan-50 text-center font-bold">Payment Verification</h2> 
            <p className="m-1"><sub>Registerer name:</sub> <sub>Usman</sub></p>
            <p className="m-1"><sub>From Kaduna state:</sub></p>
             <hr/>
             <h4 className="p-1 text-center">Made a payment</h4>
             <hr/>
            <p className="m-1"><sub>Registration Fee:</sub> <sub>1000</sub></p>
            <p className="m-1"><sub>Payment of 3 members</sub></p>

            <p className="m-1"><sub>Registration Fee per member:</sub> <sub>₦1000</sub></p>



            <p className="whitespace-nowrap absolute bottom-0 bg-slate-900 w-full flex justify-center items-center text-cyan-50 p-1"><b>Total Deposited:</b> 

            <p className="ml-2">
            <MaskText
            value={10000}
            />
            </p>
            </p>

            </div>
  

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
