import React, { useContext } from "react";
import { Button} from '@mui/material';
import {  useLocation, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";
import axios from "axios";
import baseUrl from "../../BaseURL";


function Header() {

  
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const {Person,setaccesstoken,setUserToken,Refresh,setRefresh} = useContext(ContextData)

  var day = new Date().getDate();
  var month = new Date().getMonth();
  var year = new Date().getFullYear();

  var hour = new Date().getHours();
  var minutes = new Date().getMinutes();

  const Navigate = useNavigate();
  const UseLocation = useLocation()

    // Navigate to Next route

    const handleNextPage = () =>{

      if( UseLocation.pathname === "/dashboard/report_statistics" ){
        Navigate("/dashboard/statistics") 
      }else{
        Navigate("/dashboard/report_statistics") 
      }
  }

// Next Page to logs route
const handleNextPageToLogs = ()=>{
  Navigate("/dashboard/logs") 
}



// Next Page to settings route
const handleNextPageToSettings = ()=>{
  Navigate("/dashboard/settings") 
}

const RefreshPage = () =>{
  setRefresh(!Refresh)
}

  const isActive = (pathName) =>{
        
    return UseLocation.pathname === pathName
        
  }


    const Logout = () =>{

          // Fetch Administrator Refresh Token
  async function FetchData(){

    try {
      
    const response = await axios.get(`${baseUrl}/free/logout`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json',
            }
           })

           toast.success("logout")
           setaccesstoken(null)
           setUserToken(null)
           Navigate("/login") 
           //localStorage.clear()
          
             

  
    } catch (err) {
    if(err) console.log(err)
      toast.warn(err.message)
    }

    }
    FetchData() 



    }



  // bg-gray-900 text-slate-100 p-2
  return (
    <div className={isActive("/dashboard/statistics") ? "bg-gray-900  text-slate-100 p-2 flex header shadow-2xl hide-at-prnt" : "flex header shadow-black bg-violet-50 hide-at-prnt"} >

     {/*  <div className={isActive("/dashboard/statistics") ? "flex-1 text-slate-100  px-1 flex  justify-center flex-col " : "flex-1 px-1 flex  justify-center flex-col"}>
        <p className="text-sm ">
         <b>Date:</b>  {day < 10 ? `0${day}` : day} {months[month]} {year}
        </p>
        <p className="text-sm ">
         <b>Time:</b> {hour < 10 ? `0${hour}` : hour}:
          {minutes < 10 ? `0${minutes}` : minutes}
        </p>
      </div> */}

      <div className="shrink-0 w-2/4 flex items-center ">
        <h1 className="text-4xl font-extrabold dash-text">Dashboard</h1>
        <div className='bg-gray-900 text-slate-100 p-2 dash-text-wel'>
       <h4 className='text-1xl font-bold text-center'>{Person !== null && `Welcome ${Person.fname}`} </h4>
       </div>

      </div>
             
             {
              Person !== null &&
              
             <>
                {Person.acc_level === "100"  &&
                
      <div className="flex-1 flex gap-2  items-center">
      <Button className='Hover-' onClick={handleNextPage} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
         Reload Point
       </Button>  
      <Button className='Hover-' onClick={handleNextPage} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">

        {UseLocation.pathname === "/dashboard/statistics" ? "Local Report " : "Finance Report"}        
        </Button>
        <Button className='Hover-' onClick={handleNextPageToLogs} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
          Logs   
         </Button>
         
         <Button className='Hover-' onClick={handleNextPageToSettings} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
          <SettingsIcon/> 
         </Button>
         <Button className='Hover-' onClick={Logout} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
         Logout
         </Button>
       
       {/*   <Avarta/> */}
      </div>
                }
              </>
             }


             {
              Person !== null &&
              
             <>
                {Person.acc_level === "0"  &&
                
      <div className="flex-1 flex gap-2 justify-end mr-2 items-center">
      
        {/*  <Button className='Hover-' onClick={RefreshPage} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
           Refresh Page
         </Button> */}
         <Button className='Hover-' onClick={handleNextPageToSettings} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
          <SettingsIcon/> 
         </Button>

         <div className='flex w-full justify-between hide-refrsh-logout-btns-con-header'>
         <Button className='Hover-' onClick={RefreshPage}  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
           Refresh Page
         </Button>

         <Button className='Hover-' onClick={Logout}  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
           Logout
         </Button>

         </div>

      </div>
                }
              </>
             }







    </div>
  );
}

export default Header;
