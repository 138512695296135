import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import AlertPOP from "../../../../routes/AlertPOP";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import { Avatar } from "@mui/material";
import { ContextData } from "../../../../context/Context";


export default function UserViewPopUserEdit() {



  const [open, setOpen] = React.useState(false);
  const {Refresh,setRefresh,accesstoken} = React.useContext(ContextData)
   
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };



  //Form start---------->

  const professions = ["Engineer", "Doctor", "Teacher", "Other"];
  const states = ["Abia", "Lagos", "Kano", "Ogun", "Other"];
  const countries = [
    "United States",
    "China",
    "India",
    "Brazil",
    "Russia",
    "United Kingdom",
    "France",
    "Germany",
    "Japan",
    "South Korea",
    "Canada",
    "Australia",
    "Mexico",
    "South Africa",
    "Argentina",
    "Saudi Arabia",
    "Spain",
    "Italy",
    "Netherlands",
    "Turkey",
    "Indonesia",
    "Pakistan",
    "Nigeria",
    "Egypt",
    "Bangladesh",
    "Vietnam",
    "Philippines",
    "Thailand",
    "Colombia",
    "Chile",
    "Nigeria",
    "Ethiopia",
    "Egypt",
    "DR Congo",
    "South Africa",
    "Tanzania",
    "Kenya",
    "Uganda",
    "Algeria",
    "Sudan",
    "Morocco",
  ];

  const [formData, setFormData] = useState({

    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    origin: "",
    country: "",
    stateOfOrigin: "",
    stateofresident:"",
    other_state:"",
    dateOfBirth: "",
    nin: "",
    profession: "",
    position: ""

  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };




// Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const Yeah = new Date().getFullYear()

    // Check for empty fields
   // const isEmptyField = Object.values(formData).some((value) => value === "")

/*     if (isEmptyField) {

      handleClick("left")
      setmessage("Please fill out all fields")
      return
    } */
    

    if(validator.isEmpty(formData.firstName.trim()) || validator.isEmpty(formData.lastName.trim()) || validator.isEmpty(formData.email.trim()) || validator.isEmpty(formData.mobileNumber.trim()) || validator.isEmpty(formData.origin.trim()) || validator.isEmpty(formData.country.trim()) || validator.isEmpty(formData.dateOfBirth.trim()) || validator.isEmpty(formData.nin.trim()) || validator.isEmpty(formData.profession.trim()) || validator.isEmpty(formData.position.trim()) ){
       
      handleClick("left")
      setmessage("Please fill out all fields")
         return
    }
/* 
    if(formData.origin === "indigent" && ){

    } */

    if(!validator.isNumeric(formData.mobileNumber.trim())){

      handleClick("left")
      setmessage("Mobile is not a number")
    }

    if(!validator.isEmail(formData.email.trim())){
      handleClick("left")
      setmessage("Email is not an email")
    }

    let FirstIndex = formData.dateOfBirth.indexOf("-")
    let SliceValue = formData.dateOfBirth.slice(0,FirstIndex)
    let age = Yeah - SliceValue
    const data = {...formData,age}
     
      // Submit the form (you can implement your submission logic here)

      axios.post(`${baseUrl}/auth/registermember`,data,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{

          handleClick("left")
          
            if(res.data.status){

              setmessage(res.data.statusText)
             // setRefresh(!Refresh)
              
             setFormData({
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              origin:"",
              country: "",
              dateOfBirth: "",
              nin: "",
              profession: "",
              position: "",
              stateOfOrigin: "",
              other_state:""
            }); 


            }


            if(!res.data.status){
              
              setmessage(res.data.statusText)
            }


              
        }).catch(err=> {
              handleClick("left")
            if(err) return  setmessage(err.message)
           
        })





  };

  const handleCitizenshipTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      origin: selectedOriginType,
      country: selectedOriginType === 'indigent' ? 'Nigeria' : 'Select State'
    }))

  }

  //Form end --------->

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        View/Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AlertPOP
         Open={openAlert}
         handleClose={handleCloseAlert}
         transition={transition}
         message={message}
      />
        <DialogTitle id="alert-dialog-title">
       <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)',padding:"3em"}} alt="d" src={`https://example.com/avatars/s.png`} />
          
        </DialogTitle> 
        <DialogContent>
        
            <form onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.firstName}
                onChange={handleInputChange("firstName")}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange("lastName")}
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
              <TextField
                label="Mobile Number"
                type="tel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.mobileNumber}
                onChange={handleInputChange("mobileNumber")}
              />

               <TextField
                select
                label="Origin"
                variant="outlined"
                fullWidth
                name="origin"
                margin="normal"
                value={formData.origin}
                onChange={handleCitizenshipTypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="indigent">Indigent</MenuItem>
              <MenuItem value="citizen">Citizen</MenuItem>
              </TextField>

 
              <TextField
                select
                label="Country of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.country}
                disabled={formData.origin === 'indigent'}
                onChange={handleInputChange("country")}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Date Of Birth"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.dateOfBirth}
                type="date"
                onChange={handleInputChange("dateOfBirth")}
              />
              <TextField
                label="NIN"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.nin}
                onChange={handleInputChange("nin")}
              />
              <TextField
                select
                label="Profession"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.profession}
                onChange={handleInputChange("profession")}
              >
                {professions.map((profession) => (
                  <MenuItem key={profession} value={profession}>
                    {profession}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Position"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.position}
                onChange={handleInputChange("position")}
              />

              {
                formData.origin === 'indigent' &&
              <TextField
                select
                label="State of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.origin === "indigent" ? formData.stateOfOrigin : "N/A" }
                onChange={handleInputChange("stateOfOrigin")}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={formData.origin === "indigent" ? state : "N/A"}>
                    {state}
                  </MenuItem>
                ))}
              </TextField> 
              }

              {
                formData.origin === "citizen" && 
                <>
                <TextField
               label="State of resident"
               variant="outlined"
               fullWidth
               margin="normal"
               value={formData.origin === "citizen" ? formData.stateofresident : "N/A"}
               onChange={handleInputChange("stateofresident")}
             />
           
                <TextField
               label="State of Origin from the Origin Country"
               variant="outlined"
               fullWidth
               margin="normal"
               value={formData.origin === "citizen" ? formData.other_state : "N/A"}
               onChange={handleInputChange("other_state")}
             />
                </>
              }
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
