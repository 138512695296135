import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InnerLoarder from './InnerLoarder';

export default function Loader({OpenLoader}) {

 
  
  
  return (
    <React.Fragment>
    
      <Dialog
        open={OpenLoader}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
         <div className='w-96'>
         <InnerLoarder/>
         </div>

        </DialogContent>
      
      </Dialog>
    </React.Fragment>
  );
}