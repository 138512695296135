import * as React from "react";
import { useState, useEffect, } from "react";
import axios from "axios";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Avatar,
  TextField,
  Button
} from "@mui/material";
import baseUrl from "../../../../BaseURL";
import { ContextData } from "../../../../context/Context";
import ViewNIN from "./ViewNIN";
import DialogReport from "./DialogReport";
import { toast } from "react-toastify";
 
// Replace with your base URL
 
const MemberDataPage = (props) => {

  const [profileData, setProfileData] = useState(null);
  const {accesstoken,Person,Refresh,setRefresh} = React.useContext(ContextData) 
  const [error, setError] = useState(null);


  const HandleApprove = async (token) =>{

    
    try {
      const response = await axios.put(`${baseUrl}/auth/Verified`,{token},{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{
               toast.success(res.data)
               setRefresh(!Refresh)
        }) 

       } catch (err) {
       if(err) console.log(err.message)
     //  setLoader(false)
       }

  }


  useEffect(() => {

    let routePath = props.vr === "vr" ? "verify_member_data_page" : "member_data_page"
     if(Person.acc_level === "0"){
         routePath = "registrer_member_data_page"
     }
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/auth/${routePath}/${props.token}`, {
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${accesstoken !== null && accesstoken}`
          }
        });
        setProfileData(response.data[0]);
      } catch (error) {
        setError("Error fetching profile data");
      }
    };

    fetchProfileData();



  },[props.token,Refresh]);

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!profileData) {
      if(props.vr === "vr"){
        return <Typography variant="h6">Click name to load the profile</Typography>;  
      }else{
        return <Typography variant="h6">Loading...</Typography>;
      }
   
  }

  const {
    fname,
    lname,
    email,
    mobile,
    picture,
    origin,
    country,
    stateoforigin,
    stateofresident,
    other_state,
    token,
    dateofbirth,
    age,
    nin,
    profession,
    position,
    other_profession,
    registerer_token,
    gender,
    department,
    member_address,
    ninFile,
    r_ID,
    m_status
  } = profileData;

  return (
    <div className="m-d-page-con">
    
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" , borderBlockColor:"black",borderBlockWidth:"1px",borderBlockStyle:"dotted"}}>
        <Grid className="m-d-page-con-g" container spacing={3}>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <div className="w-full flex justify-between">
            <div>
            <Typography sx={{marginTop:"1em"}} variant="h4">{`${fname} ${lname}`}</Typography>
            <Typography variant="subtitle1"><b>Email: </b>{email}</Typography>
            <Typography variant="subtitle1"><b>Mobile: </b>{mobile}</Typography>
            <Typography variant="subtitle1"><b>Department: </b>{department}</Typography>
            <Typography variant="subtitle1"><b>Marital Status: </b>{m_status}</Typography>
            <Typography variant="subtitle1"><b>Gender: </b>{gender}</Typography>
            <Typography variant="subtitle1"><b>Age: </b>{age}</Typography>
            
            </div>

            {
              props.vr === "vr" &&
              <div className="flex items-center flex-row-reverse ">
           <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)',marginLeft:"1em"}}  style={{ width: 150, height: 150, margin: "" }} alt="d" src={`${baseUrl}/files/assets/MemberPictures/${picture}`} />
            <div className="ml-5">
               <div>

               <Button onClick={()=>HandleApprove(token)} sx={{background:"blue"}} variant="contained">
                Verified
               </Button>

                 <DialogReport />
               </div>

               <p><b>Registration ID:</b> {r_ID}</p>
               <p><b>NIN ID:</b> {nin}</p>
            <ViewNIN nin={ninFile} ninId={nin}  />
            </div>
             </div>
            } 
            </div>
          </Grid> 
          <Grid item xs={12} sm={6}>
            <label>Nationality Type</label>
            <TextField
              value={origin}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Country</label>
            <TextField
              
              value={country}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>State of origin</label>
            <TextField
              
              value={stateoforigin}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>State of Resident</label>
            <TextField
              value={stateofresident}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>other State</label>
            <TextField
              value={other_state}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Date of Birth</label>
            <TextField
              value={dateofbirth}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Age</label>
            <TextField
              value={age}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>NIN</label>
            <TextField
              value={nin}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Profession</label>
            <TextField
            
              value={profession}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Position</label>
            <TextField
            
              value={position}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Other Profession</label>
            <TextField
              value={other_profession}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <label>Member Address</label>
            <TextField
              
              value={member_address}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
        </Grid>
      </Paper>
    

    </div>
  );
};

export default MemberDataPage;  