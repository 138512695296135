import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import { ContextData } from "../../../../context/Context";
import axios from "axios";
import baseUrl from "../../../../BaseURL";

export default function TransactionList(props) {

    const {setLoader,accesstoken,RefreshUesFF} = React.useContext(ContextData)
    const [Data,setData] = React.useState(null)

    React.useEffect(()=>{
     

        async function FetchData(){
                //  setLoader(true)  
    
              try {
                const response = await axios.get(`${baseUrl}/auth/get_credit_record/${props.data.token}`,{
                  withCredentials: true,
                  headers: {
                    'Accept' : 'application/json',
                    'authorization': `Bearer ${accesstoken !== null && accesstoken}`
                  }}) 
    
               setData(response.data)
             //  console.log(response.data)
             //    setLoader(false)
               
              } catch (err) {
                 if(err) console.log(err.message)
                // setLoader(false)  const {setLoader,accesstoken,RefreshUesFF} = React.useContext(ContextData)
              }
    
             
        }FetchData()
    
    
    },[])






  return (
    <div className='bg-white m-1 p-'>
         <div>
            <h4 className='text-center font-extrabold'>Transaction Record</h4>
         </div>
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {Data !== null && Data.map((value,index) => (
       <div className='bg-slate-300 border-slate-200 border-b-2 border-t-2 pl-3 pr-3 pt-3'>
         <ListItem
          key={index}
          disableGutters
         
        >
            <sub>Date: {value.createdAt}</sub>
      
        </ListItem>
        <ListItem
          key={index}
          disableGutters
          
        >
            <sub>User ID: {value.ID}</sub>
      
        </ListItem>
        <ListItem
          key={index}
          disableGutters
        
        >
          <ListItemText primary={`Paid to Registered:  ${value.amount} members`} />
        </ListItem>
        <ListItem
          key={index}
          disableGutters
        
        >
          <ListItemText primary={`Paid amount:  ₦${value.paid_amount} per member`} />
        </ListItem>
        <ListItem
          key={index}
          disableGutters
         
        >
          <ListItemText primary={`Total amount paid:  ₦${value.paid_total}`} />
        </ListItem>
     
       </div>
        
      ))}
    </List>
    </div>
  );
}