import React, { useContext } from 'react'
import { Outlet,Navigate} from 'react-router-dom'
import { ContextData } from '../context/Context'
import { useLocation } from 'react-router-dom';

const useAuth = () =>{

  const {Person} = useContext(ContextData)
  return Person !== null && Person.acc_level === "100"
}



function Permission() {
    
  const {Person} = useContext(ContextData)

  const location = useLocation();
  const IsAuth = useAuth()

  return <>{ IsAuth ? <Outlet /> : <Navigate to={`/login`} /> }</>
}

export default Permission




