import React, { useContext, useEffect, useState } from 'react';
import CardIDNum from './CardIDNum';
import baseUrl from "../../../../BaseURL";
import { ContextData } from '../../../../context/Context';

export default function PreCardShow(props) {
  const { ID_ref_Num,setID_ref_Num ,issuance,setissuance,Dep_and_post,setDep_and_post} = useContext(ContextData);
 
   const HandleIDNum = (e)=>{
    setID_ref_Num(e.target.value)
   }

   const HandleIDIssue = (e)=>{
        setissuance((prevData) => ({...prevData,[e.target.name]:e.target.value}))
   }
   const HandleDep_Post =  (e)=>{
    setDep_and_post((prevData) => ({...prevData,[e.target.name]:e.target.value}))
}

useEffect(()=>{

  
   setDep_and_post((prev) =>({...prev,department:department}))
   setDep_and_post((prev) =>({...prev,position:position}))
   
},[props.profileData])

  const {
    fname,
    lname,
    gender,
    age,
    position,
    department,
    stateoforigin,
    picture,
    r_ID
  } = props.profileData;

  return (
    <div style={{
      width: '400px',
      height: '500px',
      border: '1px solid #000',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      position: 'relative'
    }} className='hide-at-prnt'>
      <div style={{ textAlign: 'center' }}>
        <img
          src={`${baseUrl}/files/assets/MemberPictures/${picture}`}
          alt='Member-pic'
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            position: 'absolute',
            top: '20px',
            right: '20px',
          }}
        />
      </div>

      <div style={{ marginTop: '120px' }}>
        <p style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '0.5em' }}>
          {fname} {lname}
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Gender:</strong> {gender}
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Age:</strong> {age}
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Position:</strong> <input className='mb-1' style={{width:"45%",textTransform:"capitalize"}} onChange={HandleDep_Post} name='position' value={Dep_and_post.position}/>
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Department:</strong> <input className='mb-1' style={{width:"45%",textTransform:"capitalize"}} onChange={HandleDep_Post} name='department' value={Dep_and_post.department}/>
        </p>
        
        <p style={{ margin: '0.5em 0' }}>
          <strong>State of Origin:</strong> {stateoforigin}
        </p>
      </div>

      <p style={{
        position: 'absolute',
        bottom: '20px',
        fontSize: '1.2em',
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        color: 'black',
        whiteSpace: 'nowrap'
      }}>
        ID NO: FIN/<input style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDNum} value={ID_ref_Num}/>{r_ID}
        
      </p>
      <p><label className='font-bold'>State of Issue: </label><input className='mb-1' style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDIssue} name='state' value={issuance.state}/></p>
      <p><label className='font-bold'>Date of Issue: </label><input className='mb-1' style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDIssue} name='issuDate' value={issuance.issuDate}/></p>
      <p><label className='font-bold'>Expiry Date: </label><input className='mb-1' style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDIssue} name='expiry' value={issuance.expiry}/></p>
     
    </div>


  );
  
  
}