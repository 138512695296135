const Professons = 

[
    "Islamic Scholar",
   " Accountant",
  "  Actor",
  "  Architect",
 "   Artist",
 "   Attorney",
  "  Baker",
  "  Banker",
  "  Barber",
 "  Bartender",
 "  Business Man",
 "  Islamic Teacher",
 "   Biologist",
 "   Carpenter",
 "   Chef",
 "   Chemist",
 "   Civil Engineer",
 "  Civil Servant",
  "  Computer Programmer",
  "  Construction Worker",
  "  Consultant",
  "  Dentist",
   " Designer",
   " Doctor",
   " Driver",
   " Electrician",
  "  Engineer",
  "  Farmer",
   " Firefighter",
   " Graphic Designer",
   " Hairdresser",
   " Historian",
   " Journalist",
   " Lawyer",
   " Librarian",
   " Mechanic",
   " Musician",
   " Nurse",
   " Pharmacist",
   " Photographer",
   " Pilot",
   " Plumber",
   " Police Officer",
  "  Politician",
  "  Professor",
  "   Psychologist",
   " Real Estate Agent",
   " Researcher",
   " Salesperson",
   " Scientist",
   " Social Worker",
   " Software Developer",
   " Teacher",
   " Technician",
   " Translator",
    "Veterinarian",
    "Waiter/Waitress",
    "Writer",
   " Web Developer",
   "Other"
]

module.exports = Professons