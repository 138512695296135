import React from 'react'
import { ScatterChart } from '../../Contents/statistics/ScatterChart'
import { BarChart } from '../../Contents/statistics/BarChart'


function StatisticsReport() {
    
  return (
    <div className='bg-stone-900 h-full'>

    {/* First Section Chart  */}
     <BarChart/>  

    </div>
  )

}

export default StatisticsReport