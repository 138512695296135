import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Webcam from "react-webcam"; 
import {Input } from '@mui/material';
import imageCompression from "browser-image-compression"; 
import { ContextData } from "../../../../context/Context";

export default function Camera(props) {
  
  const [image, setImage] = React.useState("");
  const [NINimage, setNINimage] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [open,setOpen] = React.useState(false);
  const [isFrontCamera, setIsFrontCamera] = React.useState(true);
  const {Person,compressedImage,setCompressedImage,compressedNINImage,setCompressedNINImage} = React.useContext(ContextData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

// Uplaod to Gallary
 const handleFileInputChange = (event) => {

    const file = event.target.files[0];
     
    if (props.type === "membrPic"){
      if (file) {
        const reader = new FileReader(); // FileReader to read file contents
  
        reader.onloadend = () => {
          // Set the preview image URL
          setCompressedImage(reader.result);
        };
  
        reader.readAsDataURL(file); // Read file as data URL
      } else {
        setCompressedImage(""); // If no file selected, clear the preview
      }
    }else{

      if (file) {
        const reader = new FileReader(); // FileReader to read file contents
  
        reader.onloadend = () => {
          // Set the preview image URL
          setCompressedNINImage(reader.result);
        };
  
        reader.readAsDataURL(file); // Read file as data URL
      } else {
        setCompressedNINImage(""); // If no file selected, clear the preview
      }
    }
   
   
    

  };

  // Capture image
  const capture = async () => {
    
   const imageSrc = webcamRef.current.getScreenshot();


    if (props.type === "membrPic") {

      setImage(imageSrc);
      if (imageSrc) {
        const compressed = await compressImage(imageSrc);
        setCompressedImage(compressed);
      }


    } else {

      setNINimage(imageSrc);
      if (imageSrc) {
        const compressed = await compressImage(imageSrc);
        setCompressedNINImage(compressed);
      }
    } 





  };

  // Compress the image before sending it 
  const compressImage = async (imageSrc) => {

    const response = await fetch(imageSrc);
    const blob = await response.blob();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      const compressedBlob = await imageCompression(blob, options);
      const compressedImageSrc = URL.createObjectURL(compressedBlob);
      return compressedImageSrc;
    } catch (error) {
      console.error("Error compressing image:", error);
      return imageSrc;
    }
  };

  const webcamRef = React.useRef(null);

  const videoConstraints = {
    facingMode: isFrontCamera ? "user" : { exact: "environment" }
  };

  return (
    <React.Fragment>  
      <Button
        sx={{ marginRight: "8px", backgroundColor: props.type === "membrPic" ? "rgb(3, 3, 72)" : "rgb(2, 46, 2)" }}
        variant="contained"
        onClick={handleClickOpen}
      >
        {props.type === "membrPic" && "Capture Member"}
        {props.type === "membrNin" && "Capture Nin"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.type === "membrPic" && "Capture Photo of the Member"}
          {props.type === "membrNin" && "Capture Photo of the NIN"}
        </DialogTitle>
        <DialogContent>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={400}
            height={300}
            videoConstraints={videoConstraints}
          />
          <Button variant="contained" sx={{margin:"4px 0px",width:"100%"}} onClick={toggleCamera}>
            {isFrontCamera ? "Switch to Back Camera" : "Switch to Front Camera"}
          </Button>
          <div>
          <Button variant="contained" sx={{backgroundColor:"green",margin:"9px 0px" , width:"100%"}} onClick={capture}>Capture</Button>

          <Button variant="contained" component="label" sx={{backgroundColor:"purple", marginTop: '10px', width:"100%", position:"relative" }}>
           <span style={{whiteSpace:"nowrap,",textAlign:"center" ,position:"absolute"}}> Upload from Gallery</span>
            <Input
            type="file"
            onChange={handleFileInputChange}
            inputProps={{ accept: 'image/*' }}
            style={{ visibility:"hidden" }}
          />
          </Button>
          </div>
           <div className="w-full flex justify-center items-center">
          {props.type === "membrPic" && <img className="h-80 mt-5" src={compressedImage} alt="Captured member" />}
          {props.type === "membrNin" && <img className="h-80 mt-5" src={compressedNINImage} alt="Captured NIN" />}

           </div>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}