import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL'; 
import PersonIcon from '@mui/icons-material/Person';
import {Button} from "@mui/material";
import BadgeMax from './BadgeMax';
import { toast } from 'react-toastify';
import Deposited from '../Contents/Deposite/Deposited';
/* import EditStudentPop from './PopDialogue/Student/EditStudentPop';
 */

const Pending = () => {

  

  const [MemberData,setMemberData] = useState(null)
  const [UserData,setUserData] = useState(null)
  const [CountData,setCountData] = useState(null)
  const [Token,setToken] = useState(null)
  const {setLoader,accesstoken,Person,Refresh,setRefresh} = useContext(ContextData)

   useEffect(()=>{setRefresh(!Refresh)},[])


    useEffect(() =>{


      async function FetchData(){
        //  setLoader(true)  

      try {
        const response = await axios.get(`${baseUrl}/auth/get_data_user`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${accesstoken !== null && accesstoken}`
          }}) 

         setUserData(response.data)
         
         setCountData(response.data.length)
         setLoader(false)
       
      } catch (err) {
         if(err) console.log(err.message)
        // setLoader(false)
      }

     
}FetchData()
     
      

      
    },[Refresh])

 

    const GetTokens = (token)=>{
       
        setToken(token)
       // console.log(Token)
    }

  useEffect(()=>{
      

      async function FetchData(){
            //  setLoader(true)  

          try {
            const response = await axios.get(`${baseUrl}/auth/get_data_member_user/${Token}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 

            setMemberData(response.data)
           // setLoader(false)
           
          } catch (err) {
             if(err) console.log(err.message)
            // setLoader(false)
          }

         
    }FetchData()   


  },[Token,Refresh])

  const filteredMembers = useMemo(() => {
    return MemberData ? MemberData.filter(list => list.acc_status === 0) : [];
  }, [MemberData,Refresh]);

  const count = filteredMembers.length;


  const HandleApprove = async (token) =>{

    
    try {
      const response = await axios.put(`${baseUrl}/auth/approve`,{token},{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{
               toast.success(res.data)
              setRefresh(!Refresh)
        }) 

       } catch (err) {
       if(err) console.log(err.message)
        toast.success(err.message)
     //  setLoader(false)
       }

  }







  return (
    <div  className="con-de" style={{width:"100%",height:"100%"}}>
   
     {/*  */}
      
     
         <div className=' w-full h-full flex '>


          <div className=' w-80 h-full flex flex-col justify-center items-center '>
                 <h2 className='bg-blue-950 w-full text-3xl text-white text-center font-extrabold h-10 mb-4'>Registerers</h2>
              <div style={{height:"50rem"}}  className='overflow-y-auto w-full'>

                    <ul className='cursor-pointer v-ul-list-p'>
                      {UserData !== null && UserData.map((list, index) => (
                      <li
                      key={index}
                      onClick={() => GetTokens(list.token)}
                      className="border-b-2 border-t-0 relative border-b-slate-100 border-t-slate-950 pt-3 pb-3 mt-2 pl-1 plr-1"
                      style={{backgroundColor: list.token === Token && "green",color:list.token === Token && "white"}}
                      >
                      <PersonIcon sx={{fontSize: "2em"}} />
                      <span>{list.fname} {list.lname}</span>
                       {
                        
                       <span className='absolute z-10 top-0'> <BadgeMax token={list.token} value="pending"/></span>
                       }
                      </li>
                      ))}
                    </ul> 
              </div>
               
          </div>






          <div className='bg-slate-200 w-full h-full'>

          <h1 className='text-center text-3xl '>  <p className='font-extrabold  uppercase'>Pending</p></h1>
             <p className='pl-3 flex justify-between'><span><b>Total submitted</b>: {count} </span>
             <p>
              
            {/*  <Button className='Hover-'  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '13px', backgroundColor:'purple', margin:'0px 9px'}} onClick={HandleApproveAll}  size="small" variant="contained">
             Approve All
           </Button> */}

             </p>
             </p>

              <div style={{height:"50rem"}} className='w-full overflow-y-auto'>
         {/*     <MemberDataPage token={Token !== null && Token} vr="vr"  /> */}
                  <ul className='cursor-pointer v-ul-list-p'>
                      {MemberData !== null && MemberData.filter(list => list.acc_status === 0)
                      .map((list, index) => (
                      <li
                      key={index} 
                      className="border-b-2 flex justify-between border-t-0 border-b-slate-100 border-t-slate-950 pt-3 pb-3 mt-2 pl-1 plr-1"
                      >
                        <span className='flex justify-center items-center'>
                      <PersonIcon sx={{fontSize: "2em"}} />
                      <span>{list.fname} {list.lname}</span>

                        </span>
                      <Button onClick={()=>HandleApprove(list.token)} className='Hover-'  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '13px', backgroundColor:'green', margin:'0px 9px'}}  size="large" variant="contained">
                       Pending
                     </Button>
                      </li>
                      ))}
                    </ul> 
              </div>

         </div>







         </div>
      
   
    </div>
  );
};

export default Pending;


// get_data_adminuser