import React, { useContext } from 'react'
import { ContextData } from '../../../../context/Context'

export default function CardIDNum(props) {
     
        const {ID_ref_Num} = useContext(ContextData)

  return (
    <p style={{top:"160px",left:"4.8em",fontSize:"1.7vw",whiteSpace:"nowrap",color:"white",textTransform:"uppercase"}} className='absolute font-bold italic p-text-id-show'>ID NO: FIN/{ID_ref_Num}{props.r_ID}</p>
  )
}
