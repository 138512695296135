import React, { useContext, useEffect, useState } from 'react';
import validator from 'validator'
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePaystackPayment } from 'react-paystack';
import AlertPOP from '../../routes/AlertPOP';
import axios from 'axios';
import baseUrl from '../../BaseURL';
import nigeriaStates from './nigeriaStates';
import countries from '../home/Countries'
import kadunaLocalGovernments from './kadunaLocalGovernments ';
import { ContextData } from '../../context/Context';
import Loader from '../../routes/Loader';
import Webcam from "react-webcam"; 
import { resizeImage } from "image-conversion"; 




const useStyles = makeStyles((theme) => ({

  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    margin: 'auto',
    padding: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));




function RegisterForm(props){


  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [compressedImage, setCompressedImage] = React.useState("");
  const [OpenLoader,setOpenLoader] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

 

  //Form start---------->

  const professions = ["Engineer", "Doctor", "Teacher", "Other"];
  const states = ["Abia", "Lagos", "Kano", "Ogun", "Other"];


  const [formData, setFormData] = useState({

    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    origin: "",
    country: "",
    stateOfOrigin: "",
    stateofresident:"",
    other_state:"",
    dateOfBirth: "",
    nin: "",
    profession: "",
    position: ""

  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };




// Handle Submit
  const handleSubmit = async(e) => {
    e.preventDefault();
    const Yeah = new Date().getFullYear()

    // Check for empty fields
   // const isEmptyField = Object.values(formData).some((value) => value === "")

/*     if (isEmptyField) {

      handleClick("left")
      setmessage("Please fill out all fields")
      return
    } */
    

    if(validator.isEmpty(formData.firstName.trim()) || validator.isEmpty(formData.lastName.trim()) || validator.isEmpty(formData.email.trim()) || validator.isEmpty(formData.mobileNumber.trim()) || validator.isEmpty(formData.origin.trim()) || validator.isEmpty(formData.country.trim()) || validator.isEmpty(formData.dateOfBirth.trim()) || validator.isEmpty(formData.nin.trim()) || validator.isEmpty(formData.profession.trim()) || validator.isEmpty(formData.position.trim()) ){
       
      handleClick("left")
      setmessage("Please fill out all fields")
         return
    }
/* 
    if(formData.origin === "indigent" && ){

    } */

    if(!validator.isNumeric(formData.mobileNumber.trim())){

      handleClick("left")
      setmessage("Mobile is not a number")
    }

    if(!validator.isEmail(formData.email.trim())){
      handleClick("left")
      setmessage("Email is not an email")
    }

    let FirstIndex = formData.dateOfBirth.indexOf("-")
    let SliceValue = formData.dateOfBirth.slice(0,FirstIndex)
    let age = Yeah - SliceValue
    const data = {...formData,age}


    if (image) {

      const compressed = await resizeImage(image,{
        maxSizeMB: 1, // Maximum size in MB
        maxWidthOrHeight: 1024, // Maximum width or height
      });
      setCompressedImage(compressed);

    }
     
     // Submit the form (you can implement your submission logic here)

      axios.post(`${baseUrl}/auth/registermember`,data,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json'
        }}).then(res=>{

          handleClick("left")
          
            if(res.data.status){

              setmessage(res.data.statusText)
             // setRefresh(!Refresh)
              
             setFormData({
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              origin:"",
              country: "",
              dateOfBirth: "",
              nin: "",
              profession: "",
              position: "",
              stateOfOrigin: "",
              other_state:""
            }); 


            }


            if(!res.data.status){
              setmessage(res.data.statusText)
            }


              
        }).catch(err=> {
              handleClick("left")
            if(err) return  setmessage(err.message)
           
        })

   
  };

  const handleCitizenshipTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      origin: selectedOriginType,
      country: selectedOriginType === 'indigent' ? 'Nigeria' : 'Select State'
    }))

  }

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const webcamRef = React.useRef(null);



 




  return (
    <form className={`${classes.form}clnt-register-form h-96 flex flex-col pb-16`} style={{width:"25rem"}} onSubmit={handleSubmit}>

       <AlertPOP
       Open={open}
       handleClose={handleClose}
       transition={transition}
       message={message}
      />

       <Loader
       OpenLoader={OpenLoader}
      />

      <div className='flex flex-col innerform-inputs-con'>

     
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.firstName}
                onChange={handleInputChange("firstName")}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange("lastName")}
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
              <TextField
                label="Mobile Number"
                type="tel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.mobileNumber}
                onChange={handleInputChange("mobileNumber")}
              />

               <TextField
                select
                label="Origin"
                variant="outlined"
                fullWidth
                name="origin"
                margin="normal"
                value={formData.origin}
                onChange={handleCitizenshipTypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="indigent">Indigent</MenuItem>
              <MenuItem value="citizen">Citizen</MenuItem>
              </TextField>

 
              <TextField
                select
                label="Country of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.country}
                disabled={formData.origin === 'indigent'}
                onChange={handleInputChange("country")}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Date Of Birth"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.dateOfBirth}
                type="date"
                onChange={handleInputChange("dateOfBirth")}
              />
              <TextField
                label="NIN"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.nin}
                onChange={handleInputChange("nin")}
              />
              <TextField
                select
                label="Profession"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.profession}
                onChange={handleInputChange("profession")}
              >
                {professions.map((profession) => (
                  <MenuItem key={profession} value={profession}>
                    {profession}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Position"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.position}
                onChange={handleInputChange("position")}
              />

              {
                formData.origin === 'indigent' &&
              <TextField
                select
                label="State of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.origin === "indigent" ? formData.stateOfOrigin : "N/A" }
                onChange={handleInputChange("stateOfOrigin")}
              >
                {nigeriaStates.map((state) => (
                  <MenuItem key={state} value={formData.origin === "indigent" ? state : "N/A"}>
                    {state}
                  </MenuItem>
                ))}
              </TextField> 
              }

              {
                formData.origin === "citizen" && 
                <>
                <TextField
               label="State of resident"
               variant="outlined"
               fullWidth
               margin="normal"
               value={formData.origin === "citizen" ? formData.stateofresident : "N/A"}
               onChange={handleInputChange("stateofresident")}
             />
           
                <TextField
               label="State of Origin from the Origin Country"
               variant="outlined"
               fullWidth
               margin="normal"
               value={formData.origin === "citizen" ? formData.other_state : "N/A"}
               onChange={handleInputChange("other_state")}
             />
                </>
              }
                <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={400}
              height={300}
            />
            
              <Button onClick={capture}>Capture</Button>

              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>

              {image && <img src={image} alt="Captured" />}

            
      </div>
    
      <Button type="submit"  variant="contained" color="primary" className={classes.button}>
        Sign Up
      </Button>
     
      <br/>

    </form>
  );
};

export default RegisterForm;