import React, { useContext } from 'react'
import { Outlet,Navigate} from 'react-router-dom'
import { ContextData } from '../../context/Context'

const useAuth = () =>{

  const {accesstoken} = useContext(ContextData)
  return accesstoken !== null && accesstoken
}



function PrivateRoute() {

  const IsAuth = useAuth()
 // useAuth()
  return <>{ IsAuth ? <Outlet /> : <Navigate to="/login" /> }</>
}

export default PrivateRoute




