import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import baseUrl from "../../../../BaseURL";
import { Avatar } from "@mui/material";



export default function ViewNIN(props) {



  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  //Form end --------->

  return (
    <React.Fragment>
      <Button variant="contained" sx={{marginTop:"1em"}} onClick={handleClickOpen}>
        View NIN
      </Button>
    
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          NIN ID: {props.ninId}
        </DialogTitle> 
       
        
           <div  className="">
             <img className="" style={{width:"40rem"}} src={`${baseUrl}/files/assets/MemberPictures/${props.nin}`} alt="NIN" />
           </div>

       
        <DialogActions>
        <Button variant="contained" onClick={""}>Download</Button>
          <Button onClick={handleClose}>Done</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
