import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import validator from "validator";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AlertPOP from "../../../../routes/AlertPOP";
import baseUrl from "../../../../BaseURL";
import nigeriaStates from "./nigeriaStates"
import Professons from "./Professons"
import { ContextData } from "../../../../context/Context";

export default function AddUserDialog() {


  const [open, setOpen] = React.useState(false);
  const {Refresh,setRefresh,accesstoken} = React.useContext(ContextData)
  
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  //Form start---------->

  const professions = ["Engineer", "Doctor", "Teacher", "Other"];


  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    nin: "",
    profession: "",
    stateOfOrigin: "",
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for empty fields
    const isEmptyField = Object.values(formData).some((value) => value === "");

    if (isEmptyField) {
      // Call Alert Box function
       handleClick("left")
       setmessage("Please fill out all fields");
         return
    } 

    if(!validator.isNumeric(formData.mobileNumber.trim())){

      handleClick("left")
      setmessage("Mobile input is not a number");
      return
    } 


                 // console.log(formData)

      axios.post(`${baseUrl}/auth/user`,formData,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{

            if(res.data.status){
              
              handleClick("left")
              setmessage(res.data.statusText)
              setRefresh(!Refresh)

                setFormData({
                  firstName: "",
                  lastName: "",
                  email: "",
                  mobileNumber: "",
                  nin: "",
                  profession: "",
                  stateOfOrigin: "",
                }) 

            } 


            if(!res.data.status){
              handleClick("left")
              setmessage(res.data.statusText)
            }
              
        }).catch(err=> {

            if(err) return  setmessage(err.message)
           
        })

    

  };

  //Form end --------->

  return (
    <React.Fragment>

      <Button variant="contained" onClick={handleClickOpen}>
        Add New User
      </Button>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          New User
        </DialogTitle>
        <AlertPOP
         Open={openAlert}
         handleClose={handleCloseAlert}
         transition={transition}
         message={message}
      />
        <DialogContent>

            <form onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.firstName}
                onChange={handleInputChange("firstName")}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange("lastName")}
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
              <TextField
                label="Mobile Number"
                type="tel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.mobileNumber}
                onChange={handleInputChange("mobileNumber")}
              />
              <TextField
                label="NIN"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.nin}
                onChange={handleInputChange("nin")}
              />
              <TextField
                select
                label="Profession"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.profession}
                onChange={handleInputChange("profession")}
              >
                {Professons.map((profession) => (
                  <MenuItem key={profession} value={profession}>
                    {profession}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="State of Origin"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.stateOfOrigin}
                onChange={handleInputChange("stateOfOrigin")}
              >
                {nigeriaStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            </form>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
