import * as React from 'react';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL';
import axios from 'axios';

export default function BadgeMax(props) {

  
    const {accesstoken,Refresh} = React.useContext(ContextData)
    const [MemberData,setMemberData] = React.useState()



  React.useEffect(()=>{
      

    async function FetchData(){
            
           try {
             const response = await axios.get(`${baseUrl}/auth/get_data_member_user/${props.token}`,{
               withCredentials: true,
               headers: {
                 'Accept' : 'application/json',
                 'authorization': `Bearer ${accesstoken !== null && accesstoken}`
               }}) 
 
             setMemberData(response.data)  
             console.log(response.data)
           
           } catch (err) {
              if(err) console.log(err.message)
           }
 
     }FetchData()   

   },[Refresh])



   const filteredApproveMembers = React.useMemo(() => {
    return MemberData ? MemberData.filter(list => list.acc_status === 2) : [];
  }, [MemberData,Refresh]);

  const filteredPendingMembers = React.useMemo(() => {
    return MemberData ? MemberData.filter(list => list.acc_status === 0) : [];
  }, [MemberData,Refresh]);

  
  const ApprovedMembers = filteredApproveMembers.length;
  const PendingMembers = filteredPendingMembers.length



  return (
    <Stack spacing={4} direction="row" sx={{ color: 'action.active' }}>
       {
        props.value === "approve" && 
        <Badge color="secondary" badgeContent={ApprovedMembers} max={999}>
        <MailIcon />
      </Badge>
       }
      {
        props.value === "pending" && 
        <Badge color="secondary" badgeContent={PendingMembers} max={999}>
        <MailIcon />
      </Badge>
       }

      {
       ( props.value !== "approve" &&  props.value !== "pending") && 
        <Badge color="secondary" badgeContent={props.value} max={999}>
        <MailIcon />
      </Badge>
       }
     
    </Stack>
  );




}