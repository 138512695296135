
const baseUrl = "https://fityanulislam.mmt-ng.com"


// "https://fityanulislam.mmt-ng.com"
// http://localhost:8600




module.exports =  baseUrl