import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { usePaystackPayment } from 'react-paystack';
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import { ContextData } from "../../../../context/Context";
import MaskText from "./MaskText";
import { toast } from "react-toastify";
import Loader from "../../../../routes/Loader";



export default function FormRecharge(props) {




  const [open,setOpen] = React.useState(false);
  const {setLoader,accesstoken,setRefreshAmount,RefreshAmount} = React.useContext(ContextData)
  const [PaymentData,setPaymentData] = React.useState(null)
  const [OpenLoader,setOpenLoader] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };


  const [formData, setFormData] = useState({
    quantity: "",
    registration_type_fee:"",
   
  });
 
  
  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };


// Handle Submit
  const handleSubmit = (e) => {

    e.preventDefault();
   

    if(validator.isEmpty(formData.quantity) || validator.isEmpty(formData.registration_type_fee.toString()) ){
       
      handleClick("left")
      setmessage("Please fill out all fields")
      return
    }

       // Call Payment Getway if not empty
     //  initializePayment(onSuccess,onClose)
            
          // Call the submit formdata
          setOpenLoader(true) 
           SubmitFormdata()

  };


  // Send user Data after payment

  const SubmitFormdata = () => {

    const data = {...formData,paid_total: formData.registration_type_fee * formData.quantity, user_token: props.token,ID: props.ID}
     
    // Submit form data after payment

    axios.put(`${baseUrl}/auth/user_amount`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${accesstoken !== null && accesstoken}`
      }}).then(res=>{

        handleClick("left")
        
        setRefreshAmount(!RefreshAmount)

          if(res.data.status){

           // setmessage(res.data.statusText)
           // setRefresh(!Refresh) 
           setOpenLoader(false)
           toast.success(res.data.textStatus)
           setFormData({
            quantity: "",
            registration_type_fee:""
          }); 
          
          }


      }).catch(err=> {
            handleClick("left")
            setOpenLoader(false)
          if(err) return  setmessage(err.message)
         
      })


  }


    // Handle on success after payment
  const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
       
    // console.log(formData)
   //    alert("donr")
    };
  
// Handle close if no succeeded payment
 const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
  // alert("closed")
}
    

// Payment Getway functions
const initializePayment = usePaystackPayment(
  {
  publicKey: 'pk_test_279445fbbe7db5c0d413bbd532027d14191b107a',
  reference: (new Date()).getTime().toString(),
  email: props.email,
  amount: Number(`${formData.registration_type_fee * formData.quantity}00`),
  firstname:  props.fname,
  lastname: props.lname
   },
   
   )




  const handleQuantityTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      quantity: selectedOriginType
    }))

  }

  const handleRegistrationTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      registration_type_fee: selectedOriginType 
    }))

  }

  
  React.useEffect(()=>{
      

    async function FetchData(){
            //  setLoader(true)  
           
          try {
            const response = await axios.get(`${baseUrl}/auth/get_set_payment`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 

            setPaymentData(response.data)
            setLoader(false)
           
          } catch (err) {
             if(err) console.log(err.message)
            // setLoader(false)
          }

         
    }FetchData()


},[])

  //Form end --------->

  return (
    <React.Fragment>
             <Loader
         OpenLoader={OpenLoader}
            />
    <form  onSubmit={handleSubmit}>
            
            <TextField
                select
                label="Registration Type List"
                variant="outlined"
                fullWidth
                name="origin"
                margin="normal"
                value={formData.registration_type_fee}
                onChange={handleRegistrationTypeChange}
              >
                <MenuItem value="Select">Select</MenuItem>
                {
                  PaymentData !== null && PaymentData.map((list,key) => (  <MenuItem value={list.fee}>{list.registration_type}</MenuItem>))
                }
            
            </TextField>

               <TextField
                select
                label="Quantity"
                variant="outlined"
                fullWidth
                name="origin"
                margin="normal"
                value={formData.quantity}
                onChange={handleQuantityTypeChange}
              >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="20">20 - Members</MenuItem>
              <MenuItem value="50">50 - Members</MenuItem>
              <MenuItem value="100">100 - Members</MenuItem>
              <MenuItem value="200">200 - Members</MenuItem>
              <MenuItem value="300">300 - Members</MenuItem>
              <MenuItem value="400">400 - Members</MenuItem>
              <MenuItem value="500">500 - Members</MenuItem>
              <MenuItem value="1000">1000 - Members</MenuItem>
              <MenuItem value="2000">2000 - Members</MenuItem>
              <MenuItem value="3000">3000 - Members</MenuItem>
              <MenuItem value="4000">4000 - Members</MenuItem>
              <MenuItem value="5000">5000 - Members</MenuItem>

              </TextField>

               <div className="w-80 h-48 bg-slate-300 rounded-sm mb-3 relative">

                     <h2 className="p-3 bg-slate-900 text-cyan-50 text-center font-bold">Recharge User</h2> 

                  <p className="m-1"><b>Registration Fee:</b> <span>{formData.registration_type_fee}</span></p>
                  <p className="m-1"><b>Quantity of people:</b> <span>{formData.quantity}</span></p>
                  <p className="whitespace-nowrap absolute bottom-0 bg-slate-900 w-full flex justify-center items-center text-cyan-50 p-1"><b>Total Payment:</b> 
                  
                   <p className="ml-2">
                    <MaskText
                     value={formData.registration_type_fee * formData.quantity}
                    />
                    </p>
                  </p>

               </div>
            
              <Button type="submit" variant="contained" color="primary">
                Recharge
              </Button>

     </form>
    </React.Fragment>
  );
}
