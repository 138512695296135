import React from 'react'
import Header from '../components/Header/Header'
import Aside from '../components/Aside/Aside'
import Section from '../components/Section/Section'
import AlertPOP from './AlertPOP'



function Main() {
  return (
    <div className="main-con grid">
    <AlertPOP/>

    <Header/>
     
    <Aside/>
  
    <Section/>
    
    </div>
  )
}

export default Main