import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { usePaystackPayment } from 'react-paystack';
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import validator from "validator";
import axios from "axios";
import baseUrl from "../../../../BaseURL";
import { ContextData } from "../../../../context/Context";
import { Avatar } from "@mui/material";
import MaskText from "./MaskText";
import { toast } from "react-toastify";


export default function PopUpDeposite(props) {


     

  const [open,setOpen] = React.useState(false);
  const {setLoader,accesstoken,setRefresh,Refresh,Person} = React.useContext(ContextData)
  const [PaymentData,setPaymentData] = React.useState(null)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  const [formData, setFormData] = useState({
    quantity: "",
    registration_type_fee:"",
    submitted_form_amount: props.PendingMembers,
    registerer_token: Person !== null && `${Person.token}`,
    registerer_name: Person !== null && `${Person.fname} ${Person.lname}`,
    registerer_state: Person !== null && `${Person.stateoforigin}`
   // ID: props.data.ID,
   // user_token: props.data.token

  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };


// Handle Submit
  const handleSubmit = (e) => {

    e.preventDefault();
    
    
    if(formData.registration_type_fee === "Select" ){
        toast.warn("Select can not be use as input")
        return
      }


    if(validator.isEmpty(formData.registration_type_fee.toString()) ){
      toast.warn("Please fill out all fields")
      return
    }

       // Call Payment Getway if not empty
       initializePayment(onSuccess,onClose)

  };
  

  // Send user Data after payment

  const SubmitFormdata = () => {

    const data = {...formData,deposited_amount: formData.registration_type_fee * props.PendingMembers}
     
    // Submit form data after payment

    axios.post(`${baseUrl}/auth/deposite`,data,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'authorization': `Bearer ${accesstoken !== null && accesstoken}`
      }}).then(res=>{

      

          if(res.data.status){

            toast.success(res.data.statusText)
           setRefresh(!Refresh)
           
            
           setFormData({
            registration_type_fee:""
          }); 
          }

         
      }).catch(err=> {
            handleClick("left")
          if(err) return  toast.warn(err.message)
         
      })


  }


    // Handle on success after payment
  const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
        SubmitFormdata()
    // console.log(formData)
   //    alert("donr")
    };
  
// Handle close if no succeeded payment
 const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
    toast.warn("Closed")
  // alert("closed")
}
    

// Payment Getway functions
const initializePayment = usePaystackPayment(
  {
  publicKey: 'pk_test_9ae62dd11febf506958542f1f1f5a06089620603',
  reference: (new Date()).getTime().toString(),
  email: Person.email,
  amount: Number(`${formData.registration_type_fee * props.PendingMembers}00`),
  firstname:  Person.fname,
  lastname: Person.lname
   },
   
   )




  const handleQuantityTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      quantity: selectedOriginType
    }))

  }

  const handleRegistrationTypeChange = (e) => {

    const selectedOriginType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      registration_type_fee: selectedOriginType 
    }))

  }

  
  React.useEffect(()=>{
      

    async function FetchData(){
            //  setLoader(true)  
           
          try {
            const response = await axios.get(`${baseUrl}/auth/get_set_payment`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 

            setPaymentData(response.data)
            setLoader(false)
           
          } catch (err) {
             if(err) console.log(err.message)
            // setLoader(false)
          }

         
    }FetchData()


},[])

  //Form end --------->

  return (
    <React.Fragment>
       {
       Person !== null &&   Person.whoare_u === "mmtagent" ?
        <Button className='Hover- btn-add-mem' onClick={SubmitFormdata} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'green', margin:'9px 0px'}}  size="small" variant="contained">
         Clear Form
      </Button> :
      <Button className='Hover- btn-add-mem' onClick={handleClickOpen} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'green', margin:'9px 0px'}}  size="small" variant="contained">
         Deposite
       </Button>
       }
     
      {/*  */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogTitle id="alert-dialog-title">
      
          
        </DialogTitle> 
        <DialogContent>
       
          





        <form  onSubmit={handleSubmit}>
            
               <TextField
                   select
                   label="Payment Registration Type"
                   variant="outlined"
                   fullWidth
                   name="origin"
                   margin="normal"
                   value={formData.registration_type_fee}
                   onChange={handleRegistrationTypeChange}
                 >
                   <MenuItem value="Select">Select</MenuItem>
                   {
                     PaymentData !== null && PaymentData.map((list,key) => (  <MenuItem value={list.fee}>{list.registration_type}</MenuItem>))
                   }
               
               </TextField>



                  <div className="w-80 h-60 bg-slate-300 rounded-sm mb-3 relative">
   
                        <h2 className="p-3 bg-slate-900 text-cyan-50 text-center font-bold">Deposite</h2> 
   
                     <p className="m-1"><sub>Registration Fee:</sub> <sub>{formData.registration_type_fee}</sub></p>
                  
                     <p className="m-1"><sub>Total Pending {props.PendingMembers >=2 ? "forms" : 'form'}:</sub> <sub>{props.PendingMembers}</sub></p>
                     <p className="m-1"><sub>Waiting to receive payment of {props.PendingMembers} {props.PendingMembers >=2 ? "member's fee" : "member's fee"}</sub></p>
                     <p className="whitespace-nowrap absolute bottom-0 bg-slate-900 w-full flex justify-center items-center text-cyan-50 p-1"><b>Total Depositing:</b> 
                     
                      <p className="ml-2">
                       <MaskText
                        value={formData.registration_type_fee * props.PendingMembers}
                       />
                       </p>
                     </p>
   
                  </div>
               
                 <Button type="submit" variant="contained" color="primary">
                   Deposite
                 </Button>
   
        </form>
 

  

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
