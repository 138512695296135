import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ContextData } from "../../../../context/Context";
import Camera from "./Camera";
import { toast } from "react-toastify";
import FormMember from "./FormMember";


export default function AddMemberDialog() {


  const [open, setOpen] = React.useState(false);


  const {setRefreshAmount,RefreshAmount,accesstoken,Person,Refresh,setRefresh,compressedImage,setCompressedImage,compressedNINImage,setCompressedNINImage} = React.useContext(ContextData)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  


  //Form end --------->

  return (
    <React.Fragment>
      <Button className="btn-add-mem" variant="contained" onClick={handleClickOpen}>
        Add New Member
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogTitle id="alert-dialog-title">
          
         <div  className="flex justify-between btns-top-con-for-register">
          <p>New Member </p>
          <div>
         <Camera type="membrPic" />
         <Camera type="membrNin"  />

          </div>
         </div>
        </DialogTitle>
        <DialogContent>
        
          <FormMember />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
